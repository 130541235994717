<template>
  <div class="footer-wrap-area pt-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <router-link to="/" class="logo">
              <img src="../../assets/images/logo.png" alt="logo" />
            </router-link>
            <p>
              Boost your startup's potential with our practical app clones,
              opening doors to digital success.
            </p>
            <ul class="social-links">
              <li>
                <a
                  href="https://www.facebook.com/meandersoftware?mibextid=ZbWKwL"
                  target="_blank"
                  ><i class="ri-facebook-fill"></i
                ></a>
              </li>
              <li>
                <a href="https://x.com/meandersoftware?" target="_blank"
                  ><i class="ri-twitter-fill"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/meandersoftware/"
                  target="_blank"
                  ><i class="ri-linkedin-fill"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/meander.software/"
                  target="_blank"
                  ><i class="ri-instagram-fill"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@meandersoftware"
                  target="_blank"
                  ><i class="ri-youtube-fill"></i
                ></a>
              </li>
              <!-- <li>
                                <a href="https://github.com/" target="_blank"><i class="ri-github-fill"></i></a>
                            </li> -->
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-6">
          <div class="single-footer-widget pl-2">
            <h3>Company</h3>
            <ul class="links-list">
              <li>
                <router-link to="/about-meander">About Us</router-link>
              </li>
              <li>
                <router-link to="/success-stories">Success Stories</router-link>
              </li>
              <li>
                <router-link to="/become-a-partner"
                  >Become a Partner</router-link
                >
              </li>
              <li>
                <a
                  href="https://do.meander.software/get-placed"
                  target="_blank"
                  >Get Placed
                </a>
                <!-- <router-link to="/connect-to-learn ">Connect to Learn </router-link> -->
              </li>
              <li>
                <router-link to="/career">Career</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="single-footer-widget">
            <h3>Trending Apps</h3>
            <ul class="links-list">
              <li>
                <router-link to="/app-clones/food-ordering-app"
                  >Food Ordering App</router-link
                >
              </li>
              <li>
                <router-link to="/app-clones/grocery-delivery-app"
                  >Grocery Ordering App</router-link
                >
              </li>
              <li>
                <router-link to="/app-clones/dating-apps"
                  >Dating App</router-link
                >
              </li>
              <li>
                <router-link to="/app-clones/tiktok-clone"
                  >Tiktok Clone</router-link
                >
              </li>
              <li>
                <router-link to="/app-clones/job-marketplaces"
                  >Job Marketplaces</router-link
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-3 col-sm-6">
          <div class="single-footer-widget">
            <h3>Useful Links</h3>
            <ul class="links-list">
              <li>
                <router-link to="/privacy-policy">Privacy Policy</router-link>
              </li>
              <li>
                <router-link to="/privacy-policy">Return Policy</router-link>
              </li>
              <li>
                <router-link to="/terms-conditions"
                  >Terms & Conditions</router-link
                >
              </li>
              <li>
                <router-link to="/faq">FAQ's</router-link>
              </li>
              <li>
                <router-link to="/contact">Contact Us</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Get in touch with Quick Contact</h3>
            <p>
              Contact us effortlessly and Simplify communication for your convenience.
            </p>
            <form class="newsletter-form" @submit.prevent="submitForm">
              <input
                v-model="email"
                type="text"
                class="input-newsletter"
                placeholder="Your Email"
                name="email"
              />
              <button type="submit">
               <i class="ri-send-plane-2-line" ></i>
                
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="copyright-area">
        <p class="copyright"><span style="font-size: larger;  ">©</span>Copyright @{{currentYear}}. All Rights Reserved  <a href="https://meander.software/" target="_blank"></a></p>

      </div>
    </div>

    <div class="footer-wrap-shape">
      <img
        src="../../assets/images/more-home/footer/footer-shape-2.png"
        alt="image"
      />
    </div>
  </div>
</template>



<script>
export default {
  name: "FooterStyleFour",
  data() {
    return {
      email: "",
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    submitForm() {
      // Save the email to local storage
      localStorage.setItem("email", this.email);

      // Use Vue Router to navigate to the contact page
      this.$router.push("/contact");
    },
  },
};
</script>
