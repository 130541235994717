<template>
  <!-- <button id="zf_button_646058" style="display: none;">Form</button> -->

  <FormModal v-if="showPopup" :closePopup="closePopup" />

  <div>
    <Navbar @click="handleNavbarLinkClick" :openPopup="openPopup" />
    <!-- <Navbar @click="handleNavbarLinkClick" /> -->
    <MainBanner :data="appData" :openPopup="openPopup" />



    <!-- <iframe v-if="$route.params.slug === 'food-ordering-app'" frameborder="0" style="height:500px;width:99%;border:none;" src='https://forms.zohopublic.in/meandersoftware1/form/FoodOrderingDeliveryInquiryForm/formperma/MUtdUtGiPFWzl-1L5mOuRVLoVemyQ_hZ2mEw0AWyeKs'></iframe> -->
    <About :data="appData" :imageData="imageData" />

    <AppScreens :data="screens" />
    <IncludedBestFeatures :data="appData" />
    <Screenshots :data="screens" />
    <BestFeaturesEver :data="appData" />
    <!-- <BestFeaturesEver /> -->

    <Video v-if="appData[0]?.attributes.ms_videos?.data[0]?.attributes?.embed_video" :data="appData" />
    <Pricing />
    <PlanComparison :data="appData" />
    <Team :data="appData" />
    <iframe frameborder="0" style="height:500px;width:99%;border:none;"
      src='https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs'></iframe>;/s
    <div class="blog-details-area" v-if="this.varietiesdata[0]?.attributes?.app_varieties?.data[0]">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="section-title mt-5">
                            <span class="sub-title text-uppercase  my-3 "> </span>
 
              <h2>Similar Apps</h2>
            </div>
            <div class="blog-details-desc">
              <div class="related-post">

                <div class="row justify-content-center" style="cursor: pointer">
                  <div class="col-lg-4 col-md-4 cursor-pointer"
                    v-for="varieties in this.varietiesdata[0]?.attributes?.app_varieties?.data" :key="varieties?.id"
                    @click="goToBlogDetail(varieties?.attributes?.slug)">
                    <div class="single-blog-post cursor-pointer">
                      <div class="image cursor-pointer">
                        <div class="d-block cursor-pointer">
                          <img :src="baseUrl + varieties?.attributes?.variant_image?.data?.attributes?.url" alt="blog" />
                        </div>
                      </div>
                      <div class="content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <AppDownloadOne :data="whyUses"/>
    <Partner />

    <ScheduleDemo :data="scheduleData"/>
    <AppReviews />
    <!-- <Testimonials /> -->
    <TeamTwo v-if="!isLoading" @click="handleNavbarLinkClick" :refreshPage="changeData" />

    <Faq />

    <!-- <HowItWorks :data="appData" /> -->

    <!-- <FreeTrial /> -->
    <!-- 
        <Footer /> -->
    <FooterStyleFour />
  </div>
</template>
<style>
.zf_lB_Dimmer_646058 {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgb(0, 0, 0);
  opacity: 0.8;
  z-index: 10000000;
}

.zf_lB_Container_646058 {
  position: fixed;
  background-color: white;
  margin: 0;
  margin-right: 0px;
  padding: 0;
  height: 800px;
  width: 1497px;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: 7px solid none;
  max-height: calc(100% - 60px);
  z-index: 999999;
}

p {
  margin-bottom: 10px;
}

.zf_lB_Wrapper_646058 {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: 0;
  margin-top: -180px;
  z-index: 10000001;
}

.zf_main_id_646058 {
  height: calc(100% - 0px);
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
}

.zf_lb_closeform_646058 {
  position: absolute;
  right: -20px;
  background: #2f2e2e;
  padding: 0;
  border-radius: 50%;
  width: 34px;
  height: 34px;
  top: -15px;
  cursor: pointer;
  border: 2px solid #d9d9d9;
}

.zf_lb_closeform_646058:before,
.zf_lb_closeform_646058:after {
  position: absolute;
  left: 16px;
  content: ' ';
  height: 19px;
  width: 2px;
  top: 7px;
  background-color: #f7f7f7;
}

.zf_lb_closeform_646058:before {
  transform: rotate(45deg);
}

.zf_lb_closeform_646058:after {
  transform: rotate(-45deg);
}



@media screen and (min-device-width: 10px) and (max-device-width: 380px) {
  .zf_lB_Container_646058 {
    width: 270px !important;
  }
}

@media screen and (min-device-width: 360px) and (max-device-width: 480px) {
  .zf_lB_Container_646058 {
    width: 300px !important;
  }
}

@media screen and (min-device-width: 440px) and (max-device-width: 500px) {
  .zf_lB_Container_646058 {
    width: 380px !important;
  }
}

@media only screen and (min-width:500px) and (max-width:600px) {
  .zf_lB_Container_646058 {
    width: 450px;
  }
}

@media only screen and (min-width:601px) and (max-width:700px) {
  .zf_lB_Container_646058 {
    width: 540px;
  }
}

@media only screen and (min-width:700px) and (max-width:800px) {
  .zf_lB_Container_646058 {
    width: 650px;
  }
}

@media screen and (min-device-width: 801px) and (max-device-width: 1268px) {
  .zf_lB_Container_646058 {
    width: 750px !important;
  }
}
</style>
<script>
import axios from "axios";
import Navbar from "../Layout/Navbar";
import MainBanner from "../HomeThree/MainBanner.vue";
import About from "../AboutApp/AboutApp.vue";
import IncludedBestFeatures from "../FeaturesOne/IncludedBestFeatures.vue";
import Team from "../TeamOne/TechnologiesTeam.vue";
import BestFeaturesEver from "../FeaturesOne/AppBestFeatureEver";
import AppScreens from "../HomeThree/AppScreens";
import Screenshots from "../HomeFour/Screenshots";
import TeamTwo from "../TeamTwo/Team";
import Video from "../HomeOne/Video";
import Pricing from "../HomeOne/Pricing";
import PlanComparison from "../Common/Pricing";
import AppDownloadOne from "../AppDownload/AppDownloadOne.vue";
import Partner from "../Common/Partner.vue";
import Faq from "../Faq/Faq";
import ScheduleDemo from "../Common/AppDownload.vue";
import FooterStyleFour from "../Layout/FooterStyleFour";
import AppReviews from "./AppReviews.vue";
import FormModal from "../HomeThree/FormModal.vue";
export default {
  name: "AppClones",
  components: {
    Navbar,
    MainBanner,
    About,
    IncludedBestFeatures,
    Team,
    BestFeaturesEver,
    AppScreens,
    Screenshots,
    TeamTwo,
    Video,
    Pricing,
    PlanComparison,
    AppDownloadOne,
    Partner,
    Faq,

    ScheduleDemo,

    FooterStyleFour,
    AppReviews,
    FormModal
  },
  data() {
    return {
      baseUrl: "https://content.meander.software/",
      appData: [],
      screens: [],
      blogs: [],
      varietiesdata: [],
      imageData:[],
      whyUses:[],
      scheduleData:[],
      title: "",
      hotjarScript: "",
      isLoading: false,
      showPopup: false
    };
  },
  async mounted() {
    // scriptt();
    await this.getData();
    // await this.getData2();
    this.getBlogs();
    // const script = document.createElement('script');
    // script.src = scriptt;
    // document.body.appendChild(script);
  },
  watch: {
    "$route.params.slug": {
      immediate: true,
      handler(newSlug) {
        this.varietiesdata = [];
        if (newSlug) {
          this.getData2(newSlug);
        }
      },
    },
  },
  methods: {
      openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    async getData() {
      try {
        const slug = this.$route.params.slug;
        const response = await axios.get(
          `${this.$baseUrl}/apps?filters[slug][$eq]=${slug}&populate=*`
        );
        const responseimage = await axios.get(
          `${this.$baseUrl}/apps?filters[slug][$eq]=${slug}&populate=*&populate=ms_apps_about_uses.image`
        );
        const responseWhyus = await axios.get(
          `${this.$baseUrl}/apps?filters[slug][$eq]=${slug}&populate=*&populate=ms_apps_why_uses.image`
        );
        const responseSchedule = await axios.get(
          `${this.$baseUrl}/apps?filters[slug][$eq]=${slug}&populate=*&populate=ms_apps_schedule_demos.image`
        );
        this.imageData = responseimage?.data?.data
        this.whyUses = responseWhyus?.data?.data
        this.scheduleData = responseSchedule?.data?.data

        
        console.log("thisimageData--->",this.scheduleData)
        const { data } = response;
        this.appData = data.data;

        this.title =
          this.appData[0]?.attributes?.ms_common_meta_tags?.data[0]?.attributes?.title;
        const description =
          this.appData[0]?.attributes?.ms_common_meta_tags?.data[0]?.attributes
            ?.description;
        document.title = this.title || "apps";
        const metaDescription = document.querySelector(
          'meta[name="description"]'
        );

        if (metaDescription) {
          // If the <meta> tag exists, update its content
          metaDescription.content = description;
        } else {
          // If the <meta> tag doesn't exist, create a new one and append it to the <head> section
          const newMetaTag = document.createElement("meta");
          newMetaTag.name = "description";
          newMetaTag.content = description;
          document.head.appendChild(newMetaTag);
        }
        this.getScreens();
      } catch (error) {
        this.appData = [];
      }
    },
    async getData2() {
      try {
        const slug = this.$route.params.slug;
        const response = await axios.get(
          `${this.$baseUrl}/apps?filters[slug][$eq]=${slug}&populate=app_varieties.variant_image`
        );
        const { data  } = response;
        this.varietiesdata = data.data;
        console.log(this.varietiesdata,'======')
        this.getScreens();
        this.getData()
      } catch (error) {
        this.varietiesdata = [];
      }
    },
    async getScreens() {
      try {
        const slug = this.$route.params.slug;
        // Fetch best features data from Strapi API
        const response = await axios.get(
          `${this.$baseUrl}/apps?filters[slug][$eq]=${slug}&populate=*&populate=screens.mobile_screens&populate=screens.web_screens `
        );
        const { data  } = response;
        this.screens = data.data;
      } catch (error) {
        this.screens = [];
      }
    },
    async changeData(data) {
      const routeObject = { name: "app-clones", params: { slug: data } };
      await this.$router.push(routeObject);
      this.getData();
    },
    async getBlogs() {
      try {
        const response = await axios.get(
          `${this.$baseUrl}/blogs?pagination[limit]=10&populate=*`
        );
        const { data } = response;
        this.blogs = data.data;
      } catch (error) {
        this.blogs = [];
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
    async handleNavbarLinkClick(event) {
      this.isLoading = true;
      if (event?.target?.href?.includes("app-clones")) {
        await this.getData();
      }
      this.isLoading = false;
    },
    goToBlogDetail(slug) {
      console.log(slug);
      this.$router.push(`/app-clones/${slug}`);
      this.changeData(slug);
    },
  },
  computed: {
    appDataComputed() {
      // Here you performed  additional data manipulation
      return this.appData;
    },
  },

  // beforeUnmount() {
  //   // Remove the dynamically added script here
  //   const scriptElement = document.getElementById('your-script-id');
  //   if (scriptElement) {
  //     scriptElement.remove();
  //   }
  // },

};
</script>
