<template>
    <div class="banner-wrapper-area" v-if="data[0]?.attributes?.title">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-wrapper-content">
                        <span class="sub-title text-uppercase  ">{{ data[0]?.attributes?.hash_tag || "Meander"}}</span>
                        <h1>{{data[0]?.attributes?.title}}</h1>
                        <p>{{data[0]?.attributes?.description}}</p>
                        <button @click="openCalendly" class="default-btn">Schedule Demo</button>
                        <button class="default-btn ms-2" @click="openPopup">Explore Solutions</button>
 <!-- <button class="default-btn ms-2" >
     
    <a href='https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs' title="Request Call back Form" target='_blank' onclick="zforms_open_window(this.href, 648, 700); return false">Access Form</a>





                        </button> -->
                        <div>
    
    

  </div>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <!-- <div v-if="isLoading">
                        <Loader/>
                    </div> -->
                    <div class="banner-wrapper-image" v-if="data[0]?.attributes?.screenshot_1?.data?.attributes?.url && data[0]?.attributes?.screenshot_2?.data?.attributes?.url">
                        <img :src="baseUrl+data[0]?.attributes?.screenshot_1?.data?.attributes?.url" alt="banner-img">
                        <img :src="baseUrl+data[0]?.attributes?.screenshot_2?.data?.attributes?.url" data-aos="fade-left" alt="banner-img">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="showPopup"  @click="closePopup" class="popup-overlay">
        <div class="popup">
      <iframe :src="formUrl" width="700" height="648"></iframe>
      <button>Close</button>
      </div>
    </div> -->
        <div class="shape13">
            <img src="../../assets/images/shape/shape15.png" alt="shape">
        </div>
        <div class="shape14">
            <img src="../../assets/images/shape/shape17.png" alt="shape">
        </div>
        <div class="shape15">
            <img src="../../assets/images/shape/shape18.png" alt="shape">
        </div>
    </div>

    
</template>

<style scoped>
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    /* z-index: 99999999999999 !important; */
}

.popup {
    background-color: white;
    /* padding: 20px; */
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 700px;
    height: 600px;
}

button {
  margin-top: 10px;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
<script>

import Loader from '../Loader/Loader.vue'
import FormModal from './FormModal.vue'
export default {
    name: 'MainBanner',
    props: {
  openPopup: Function, // Use the Function type directly
  data: {
      type: [], // Set the prop type to be Any
      required: true,

    },
},

    components:{
    Loader,
    FormModal
},
    data() {
    return {
      baseUrl: 'https://content.meander.software',
      isLoading: true,
    //   showPopup: false,
      // formUrl: "https://forms.zohopublic.in/meandersoftware1/form/FoodOrderingDeliveryInquiryForm/formperma/MUtdUtGiPFWzl-1L5mOuRVLoVemyQ_hZ2mEw0AWyeKs"
    }
  },
  mounted(){
this.data  
setTimeout(()=>{
this.isLoading=false;
},5000)

},
    methods: {
    //     openPopup() {
    //   this.showPopup = true;
    // },
    // closePopup() {
    //   this.showPopup = false;
    // },
    //     openFormPopup() {
    //   zforms_open_popup(
    //     'https://forms.zohopublic.in/meandersoftware1/form/FoodOrderingDeliveryInquiryForm/formperma/MUtdUtGiPFWzl-1L5mOuRVLoVemyQ_hZ2mEw0AWyeKs',
    //     648,
    //     700
    //   );
    // },
      openCalendly() {
     // eslint-disable-next-line no-undef
      Calendly.initPopupWidget({url:'https://calendly.com/connect-to-meander/30min'});
return false;
    }

  }
}
</script>