<template>
	<div  v-on:click="savedata" ref="recaptchaDiv"></div>
</template>
  
<script setup>
import { ref, onMounted } from 'vue'

const recaptchaDiv = ref(null)
let recaptcha = null

// eslint-disable-next-line no-undef
const props = defineProps({
	sitekey: {
		type: String,
		required: true
	},
	size: {
		type: String,
		required: false,
		default: "normal"
	},
	theme: {
		type: String,
		required: false,
		default: "light"
	},
	hl: {
		type: String,
		required: false
	},
	loadingTimeout: {
		type: Number,
		required: false,
		default: 0
	}
})

// eslint-disable-next-line no-undef
const emit = defineEmits()
function savedata(){
console.log('errrrorrrrrrrr');
    }
// eslint-disable-next-line no-undef
defineExpose({
	execute: () => {

		window.grecaptcha.execute(recaptcha)
	},
	reset: () => {
		window.grecaptcha.reset(recaptcha)
	}
})




function renderRecaptcha() {
	console.log('abcddddd');
	
	recaptcha = window.grecaptcha.render(recaptchaDiv.value, {

		'sitekey': '6Lc5ZF0dAAAAAOvMq-Wul9-BuNRmVN5W8aX19i1E',
		'theme': props.theme,
		'size': props.size,
		'callback': (response) => emit("verify", response),
		'expired-callback': () => emit("expire"),
		'error-callback': () => emit("fail")
	})
}

onMounted(() => {
	if (window.grecaptcha == null) {
		new Promise((resolve, reject) => {
			let loadingCountdown
			let responded = false

			window.recaptchaReady = function () {
				if (responded) return
				responded = true
				clearTimeout(loadingCountdown)
				resolve()
			}

			const scriptId = "recaptcha-script"
			const loadingFailed = (reason) => {
				return () => {
					if (responded) return
					responded = true
					clearTimeout(loadingCountdown)
					document.getElementById(scriptId)?.remove()
					reject(reason)
				}
			}

			if (props.loadingTimeout > 0) loadingCountdown = setTimeout(loadingFailed("timeout"), props.loadingTimeout)

			const doc = window.document
			const scriptTag = doc.createElement("script")
			scriptTag.id = scriptId
			scriptTag.onerror = loadingFailed("error")
			scriptTag.onabort = loadingFailed("aborted")
			scriptTag.setAttribute("src", `https://www.google.com/recaptcha/api.js?onload=recaptchaReady&render=explicit&hl=${props.hl}&_=${+new Date()}`)
			doc.head.appendChild(scriptTag)
		}).then(() => {
			renderRecaptcha()
		}).catch((err) => {
			emit("error", err)
		})
	} else {
		renderRecaptcha()
	}
})
</script>
  