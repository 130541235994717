
<template>
    <div>
    <Navbar />
    <!-- <PageTitle pageTitle="Templates & Themes" /> -->
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="widget widget_search">
                            <form class="search-form">
                                <label><input type="search" class="search-field" placeholder="Search..."></label>
                                <button type="submit"><i class="ri-search-2-line"></i></button>
                            </form>
                        </div>
            <div class="row justify-content-center">
              
                <div v-for="data in appDATA?.data" :key="data?.id" class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <router-link :to="`/app-detail/${data?.attributes?.slug}`" class="d-block">
                                <img style="width:100%;  " :src="baseUrl + data?.attributes?.circle_image?.data?.attributes?.formats?.thumbnail?.url" alt="blog">
                            </router-link>
                            <router-link :to="`/app-detail/${data?.attributes?.slug}`" class="tag">{{data?.attributes?.hash_tag}}</router-link>
                        </div>
                        <div class="content">
                            <ul class="meta">
                                <li><i class="ri-time-line"></i> {{dateFormat(data?.attributes?.updatedAt)}}</li>
                                <li><i class="ri-message-2-line"></i> <router-link :to="`/app-detail/${data?.attributes?.slug}`">({{randomNumber()}}) Comment</router-link></li>
                            </ul>
                            <h3><router-link :to="`/app-detail/${data?.attributes?.slug}`">{{data?.attributes?.title}}</router-link></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <div class="nav-links">
  <!-- Previous Page Link -->
  <router-link 
    v-if="currentPage > 1" 
    :to="`/templates-and-themes?page=${currentPage - 1}`" 
    class="prev page-numbers" 
    title="Previous Page">
    <i class="ri-arrow-left-line"></i>
  </router-link>

  <!-- Loop through pages dynamically -->
  <router-link
    v-for="page in totalPages"
    :key="page"
    :to="`/templates-and-themes?page=${page}`"
    :class="['page-numbers', { current: currentPage === page }]">
    {{ page }}
  </router-link>

  <!-- Next Page Link -->
  <router-link
    v-if="currentPage < totalPages"
    :to="`/templates-and-themes?page=${currentPage + 1}`"
    class="next page-numbers"
    title="Next Page">
    <i class="ri-arrow-right-line"></i>
  </router-link>
</div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    <FooterStyleFour />
</template>

<script>
import axios from "axios";
import Navbar from '../Layout/Navbar';
// import PageTitle from '../Common/PageTitle';
import FooterStyleFour from '../Layout/FooterStyleFour';
export default {
  name: 'TemplatesAndThemes',
  components: {
        Navbar,
        // PageTitle,
        FooterStyleFour,
    },
  data() {
    return {
      baseUrl: 'https://content.meander.software',
      relatedApps: [],
      appDATA: [],
      filteredApps: [],
      matchedPerson: [],
      slugToMatch: '',
      currentPage:1,
      pageSize:9,
      total:0,
      totalPages: 0 // Initialize as a number, not an array
    }
  },
  methods: {
    async getApp() {
      try {
        const response = await axios.get(`${this.baseUrl}/api/apps?populate=circle_image&pagination[page]=${this.currentPage}&pagination[pageSize]=${this.pageSize}`);
        const { data, meta } = response;
        this.appDATA = data;
        this.total = meta?.pagination?.total || 0; // Total number of items
        this.totalPages = Math.ceil(this.total / this.pageSize);
      } catch (error) {
        console.error(error);
      }
    },
    randomNumber(){
      return Math.floor(Math.random() * 100);
    },
    dateFormat(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(date).toLocaleDateString('en-US', options);
    }
  },
  async mounted() {
      await this.getApp();
  }
}
</script>
