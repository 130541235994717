<template>
    <div class="screenshots-area bg-black-color ptb-100" v-if="data[0]?.attributes?.screens?.data[0]?.attributes?.mobile_screens?.data">
        <div class="container">
            <div class="section-title color-white">
                <span class="sub-title text-uppercase  ">APP SCREEN</span>
                <h2>Beautifully Crafted All App Screenshots</h2>
            </div>
            <div class="screenshots-slides">
                <carousel
                    :wrap-around="true"
                    :autoplay="3000"
                    :settings='settings'
                    :breakpoints='breakpoints'
                >
                    <slide 
                        v-for="slide in data[0]?.attributes?.screens?.data[0]?.attributes?.mobile_screens?.data" 
                        :key="slide.id"
                    >
                        <div class="single-screenshot-item">
                            <img :src="baseUrl+slide?.attributes?.url" alt="screenshots">
                        </div>
                    </slide>
                    
                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class='ri-arrow-right-s-line'></i>
                            </template>
                            <template #prev>
                                <i class='ri-arrow-left-s-line'></i>
                            </template>
                        </navigation>
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'AppScreens',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    props: {
        data: {
            type: [], // Set the prop type to be Any
            required: true,
        },
    },
    data: () => ({
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'left',
			},
            576: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            768: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            992: {
                itemsToShow: 4,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 5,
                snapAlign: 'center',
            },
        },
        baseUrl: 'https://content.meander.software',

    })
})
</script>