<template>
  <FormModal v-if="showPopup" :closePopup="closePopup" />

  <div>
    <Navbar @click="handleNavbarLinkClick" :openPopup="openPopup" />
    <!-- <PageTitle pageTitle="Services" /> -->
    <!-- <ChallengesAndTrack /> -->
    <IndustriesAbout :data="ApiData" />

   <!--<IndustriesStats :data="ApiData" /> --> 
   <Funfacts/>
    <!-- <Team /> -->
    <IndustriesService :data="service"/>
    <IndustriesDevelopmentProcess :data="ApiData" />

    <IndustriesRequestDemo :data="ApiData" />
    <!-- <BestFeaturesEver /> -->
    <IndustriesDiffcompany :data="ApiData"/>
    <IndustriesOffers :data="ApiData" />
    <!-- <CustomFeatures /> -->
    <AppScreens />
    <Faq />
    <iframe frameborder="0" style="height:500px;width:99%;border:none;" src='https://forms.zohopublic.in/meandersoftwarecontact/form/ClientNeedsAssessmentForm/formperma/4fjH7ZBQUI7rdg6ZqA85n4ldQD4ajNkC_-RXMdrXXPs'></iframe>

    <!-- <FreeTrial /> -->
    <!-- 
        <IncludedBestFeatures />
        <BestFeaturesEver />
        <AppDownload /> -->
    <FooterStyleFour />
  </div>
</template>

<script>
import Navbar from "../../Layout/Navbar";
import IndustriesAbout from "../../Pages/Industry/IndustriesAbout";
// import ChallengesAndTrack from  "../../AboutOne/ChallengesAndTrack"
 
 
import AppScreens from "../../HomeOne/AppScreens";
import Faq from "../../Faq/Faq";

// import IncludedBestFeatures from '../../Services/IncludedBestFeatures'
// import BestFeaturesEver from '../../Services/BestFeaturesEver'
// import AppDownload from '../../Services/AppDownload'
import FooterStyleFour from "../../Layout/FooterStyleFour";
import axios from "axios";
import IndustriesStats from "./IndustriesStats";
import IndustriesRequestDemo from "./IndustriesRequestDemo";
import IndustriesDevelopmentProcess from "./IndustriesDevelopmentProcess.vue";
import IndustriesOffers from "./IndustriesOffers";
import IndustriesDiffcompany from "./IndustriesDiffcompany";
import IndustriesService from './IndustriesService.vue'
import FormModal from "../../HomeThree/FormModal.vue";
import Funfacts from "../../AboutOne/Funfacts.vue";
export default {
  name: "IndustriesClone",
  components: {
    Navbar,
 
    IndustriesAbout,
    IndustriesStats,
    
    IndustriesRequestDemo,
  
    AppScreens,
    Faq,
 
    // Features,
    // IncludedBestFeatures,
    // BestFeaturesEver,
    // AppDownload,
    FooterStyleFour,
    IndustriesDevelopmentProcess,
    IndustriesOffers,
    IndustriesDiffcompany,
    IndustriesService,
    FormModal,
    Funfacts
},
  data() {
    return {
      baseUrl: "https://content.meander.software/",
      ApiData: {},
      service:[],
      isLoading: false,
      showPopup:false
    };
  },
 
  async mounted() {
    await this.getData();
    await this.getData2();

  },
  methods: {
      openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
    },
    async getData() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get(
          `${this.$baseUrl}/ms-industries?filters[slug][$eq]=${slug}&populate=*`
        );
        const { data  } = response;
        this.ApiData = data.data;
        const description = this.ApiData[0].attributes.ms_common_meta_tags?.data[0]?.attributes?.description;         
        this.title = this.ApiData[0]?.attributes?.ms_common_meta_tags?.data[0]?.attributes?.title
        document.title = this.title || "industry"
        const metaDescription = document.querySelector('meta[name="description"]');

        if (metaDescription) {
          // If the <meta> tag exists, update its content
          metaDescription.content = description;
        } else {
          // If the <meta> tag doesn't exist, create a new one and append it to the <head> section
          const newMetaTag = document.createElement('meta');
          newMetaTag.name = 'description';
          newMetaTag.content = description;
          document.head.appendChild(newMetaTag);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData2() {
      const slug = this.$route.params.slug;
      try {
        const response = await axios.get(
          `${this.$baseUrl}/ms-industries?filters[slug][$eq]=${slug}&populate=ms_industries_key_services_industries.image`
        );
        const { data  } = response;
        this.service = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async handleNavbarLinkClick(event) {
      this.isLoading = true;
      if (event?.target?.href?.includes("industries")) {
        await this.getData();
        await this.getData2();
      }
      this.isLoading = false;
    },
  },
};
</script>
